import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Solitaire from '../../../../Assets/images/engagement/solitaire_ring.webp';
import Pave from '../../../../Assets/images/engagement/pave_ring.webp';
import Halo from '../../../../Assets/images/engagement/halo_ring.webp';
import Vintage from '../../../../Assets/images/engagement/vintage_ring.webp';
import ThreeStone from '../../../../Assets/images/engagement/three_stone_ring.webp';
import ChannelSet from '../../../../Assets/images/engagement/single_row_ring.webp';
import Multirow from '../../../../Assets/images/engagement/multirow_ring.webp';
import Bypass from '../../../../Assets/images/engagement/bypass_ring.webp';
import SideStone from '../../../../Assets/images/engagement/side_stone_ring.webp';
import other from '../../../../Assets/images/engagement/trellis_ring.webp';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import prevImg from '../../../../Assets/images/hala-london-img/prev-img.png'
import nextImg from '../../../../Assets/images/hala-london-img/next-img.png'
const style_submenu = [
    {
        "menu_id": "24",
        "name": "Halo",
        "img_url":  Halo ,
        "icon": "",
        "alt_tag": "",
        "slug": "\/ringsettings\/style=Halo",
        "col": "3",
        "design": "1",
        "submenu": []
    },
    {
        "menu_id": "18",
        "name": "Solitaire",
        "img_url": Solitaire ,
        "icon": "",
        "alt_tag": "",
        "slug": "\/ringsettings\/style=Solitaire",
        "col": "3",
        "design": "1",
        "submenu": []
    },
    {
        "menu_id": "17",
        "name": "Three Stone",
        "img_url": ThreeStone ,
        "icon": "",
        "alt_tag": "",
        "slug": "\/ringsettings\/style=Three%20stone",
        "col": "3",
        "design": "1",
        "submenu": []
    },
    {
        "menu_id": "20",
        "name": "Single Row",
        "img_url": ChannelSet ,
        "icon": "",
        "alt_tag": "",
        "slug": "\/ringsettings\/style=Single Row",
        "col": "3",
        "design": "1",
        "submenu": []
    },
    {
        "menu_id": "203",
        "name": "Multirow",
        "img_url": Multirow ,
        "icon": "",
        "alt_tag": "",
        "slug": "\/ringsettings\/style=Multirow",
        "col": "3",
        "design": "1",
        "submenu": []
    },
    {
        "menu_id": "204",
        "name": "Bypass",
        "img_url": Bypass ,
        "icon": "",
        "alt_tag": "",
        "slug": "\/ringsettings\/style=Bypass",
        "col": "3",
        "design": "1",
        "submenu": []
    },
    {
        "menu_id": "23",
        "name": "Pave",
        "img_url":  Pave ,
        "icon": "",
        "alt_tag": "",
        "slug": "\/ringsettings\/style=Pave",
        "col": "3",
        "design": "1",
        "submenu": []
    },
    {
        "menu_id": "21",
        "name": "Vintage",
        "img_url": Vintage ,
        "icon": "",
        "alt_tag": "",
        "slug": "\/ringsettings\/style=Vintage",
        "col": "3",
        "design": "1",
        "submenu": []
    }]
const RingStyle = () => {
    var history = useHistory();

  

    var settingsV2 = {
        dots: false,
        arrows: true,
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        autoplay: true,
        prevArrow:<img src={prevImg} alt="prev img"/>,
    nextArrow:<img src={nextImg} alt="prev img"/>,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const shopbystyle = (value) => {
        var data1 = {
            style: value,
        }
        sessionStorage.setItem("bw_s_filter", JSON.stringify(data1));
        history.push(value);
    }
    return (
        <>
            <Container className='rcs_custom_home_container mt-3' >
                <div className='rcs_ringstyle rcs_ringstyle_v2' >
                    <Row>
                    <Col md={12}>
              <div className="hl_diamond_headings">
                <h2>Shop By Style</h2>
                <p>Find Your Signature Look</p>
              </div>
            </Col>
                    </Row>

                    <Slider {...settingsV2}>
                        {style_submenu?.map((p_style, i) =>
                        <div className='rcs_ringstyle_content_box '>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage src={p_style?.img_url} alt='Solitaire' onClick={() => shopbystyle(p_style?.slug)} />
                            </div>
                            <h2 onClick={() => shopbystyle(p_style?.slug)}>{p_style?.name}</h2>
                        </div>
                        )}
                    </Slider>

                </div>


            </Container>
        </>
    );
}

export default RingStyle;