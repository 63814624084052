import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "../../Assets/css/home.css";
import "../../../../Assets/css/halaStyle.css";

import { isMobile, isMobileOnly } from "react-device-detect";
import { Button, Skeleton } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { base_url, postHeader } from "../../../../Helpers/request";
import { useState } from "react";
import { toast } from "react-toastify";
import bannerImg from "../../../../Assets/images/hala-london-img/home-banner.webp";
import bannerImgMobile from "../../../../Assets/images/hala-london-img/home-banner-mobile.webp";
import { diamondpreloader } from "../../../../Reducer/homeReducer";
import { useDispatch } from "react-redux";

const BannerSlider = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(diamondpreloader(true));
    setLoading(true);
    //-----------------------------------Banner
    axios
      .get(base_url + `/home/slider`, {
        headers: postHeader,
      })
      .then((response) => {
        if (response.data.status == 1) {
          dispatch(diamondpreloader(false));
          setBanner(response.data.data);
          setLoading(false);
          localStorage.setItem(
            "bw-bannerdata",
            JSON.stringify(response.data.data)
          );
        } else {
          dispatch(diamondpreloader(false));
          setLoading(false);
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {loading ? (
        <Skeleton variant="text" animation="wave" height={875} />
      ) : (
        banner?.map((val, index) => (
          <div
            className="rcs_hero_slider"
            style={{
              backgroundImage: `url(${
                // isMobileOnly ? val.mobile_image : val.image
                isMobileOnly ?  bannerImgMobile :bannerImg 
              })`,
            }}
          >
            <div className="rcs_hero_img">
              <Container fluid>
                <Row className="m-auto w-100">
                  <Col md={9} className="m-auto">
                    <div
                      className={
                        isMobileOnly
                          ? "text-center mobile_style_banner"
                          : val.text_direction_class
                      }
                    >
                      <h1>
                        Your Story.<br></br>Our Masterpieces.
                      </h1>
                      <p>
                        The Missing Puzzle To Your Wonderful Story. Shop Our
                        Collection.
                      </p>
                      <div className={isMobileOnly ? " text-center" :""}>
                      <Button
                        onClick={() => {
                          history.push("/" + val.btn_link);
                        }}
                        variant="outline-dark"
                      >
                        Shop Now
                      </Button>
                      </div>
                      
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default BannerSlider;
