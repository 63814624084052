import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  Image,
  InputGroup,
  Row,
  Form,
  Modal,
} from "react-bootstrap";
// import signupTo from "../../../../Assets/images/home/signup-to.webp";
import {
  base_url,
  client_name,
  currency,
  // currencycode,
  helmet_url,
  postHeader,
} from "../../../../Helpers/request";
import { useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Loader from "../../../Front/showcase_2.o/Components/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";

function SignupTo() {
  const history = useHistory();
  const [newsletteremail, setNewsletteremail] = useState("");
  const [loader, setLoader] = useState(false);
  const newsletter = (e) => {
    e.preventDefault();
    setLoader(true);
    const data = {
      // currency_code: currencycode,
      email: newsletteremail,
    };
    axios
      .post(base_url + "/common/subscribe_newsletter", data, {
        headers: postHeader,
      })
      .then((res) => {
        if (res.data.status == 1) {
          setLoader(false);
          setNewsletteremail("");
          return swal(res.data.message, "", "success");
        } else {
          setLoader(false);
          return swal(res.data.message, "", "error");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  return (
    <>
      <section className="rcs_signup_to_section mt-5 mt-lg-5 mt-sm-3 mt-xs-0">
        <Container fluid>
          <Row>
            <Col
              lg={{ span: 6, offset: 3 }}
              md={{ span: 8, offset: 2 }}
              xs={12}
              className="d-flex align-items-center"
            >
              <div className="rcs_signup_to_left_main">
                <div className="hl_diamond_headings">
                  <h2>Subscribe To Our Newsletter</h2>
                  <p>
                    Keep up to date with our latest offers, competitions and
                    news, and be a part of the Heart Of Diamond community!
                  </p>
                </div>
                <div className="rcs_signup_to_input_field">
                  <Form className="gs_news_form w-100" onSubmit={newsletter}>
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="Email Address..."
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        type="email"
                        value={newsletteremail}
                        required
                        onChange={(e) => setNewsletteremail(e.target.value)}
                      />
                      <Button
                        variant="outline-secondary"
                        type="submit"
                        id="button-addon2"
                      >
                        Subscribe
                      </Button>
                    </InputGroup>
                  </Form>
                  {/* <p>By signing up you confirm that you have read the <a href="/privacy-policy">Privacy Policy</a> and agree that your email */}
                  {/* will be collected and used for the purposes of sending news, promotions and updates via email. You can withdraw your consent at any time by unsubscribing.</p> */}
                </div>
              </div>
            </Col>
            {/* <Col lg={6} xs={12} className="ml-auto pr-0 p-0">
                            <div className="  rcs_signup_to_right_img">
                                <LazyLoadImage src={signupTo} alt="signup" />
                            </div>
                        </Col> */}
          </Row>
        </Container>
      </section>
      <Modal show={loader}>
        <Loader />
      </Modal>
    </>
  );
}

export default SignupTo;
