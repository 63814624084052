import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import RingGuidImg from "../../../../Assets/images/hala-london-img/ring-guide.webp";
import DiamondGuidImg from "../../../../Assets/images/hala-london-img/diamond-guide.webp";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BecomeAnExpert = () => {
  const history = useHistory()
  return (
    <>
      <div className="hl_become_An_expert_section">
        <Container className="hl_custome_container">
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="hl_diamond_headings">
                <h2>Become an Expert</h2>
                <p>Level Up Your Knowledge</p>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={12} md={6} lg={6}>
              <div className="hl_expert_text_img_box">
                <Image
                  src={RingGuidImg}
                  alt="Ring Guide Image"
                  className="img-fluid"
                />
                <div className="hl_guild_text_boxx">
                  <h6>Ring Guides</h6>
                  <p>
                    How to buy an engagement ring. Easy guidance on how to set a
                    budget, choose a ring metal, and find the right style and
                    ring size when buying a ring.
                  </p>
                  <button
                    className="hl_btn_outline"
                    onClick={() => window.open("https://venice.demobw.com/assets/images/eng-ring/Ring-sizing-guide.pdf", "_blank")}
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="hl_expert_text_img_box">
                <Image
                  src={DiamondGuidImg}
                  alt="Diamond Guide Image"
                  className="img-fluid"
                />
                <div className="hl_guild_text_boxx">
                  <h6>Diamond Guides</h6>
                  <p>
                  We have created a comprehensive collection of diamond
guides, covering everything from diamond shapes to
lab grown diamonds.
                  </p>
                  <button
                    className="hl_btn_outline"
                    onClick={() => history.push("/education/loose-diamonds-education")}
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BecomeAnExpert;
