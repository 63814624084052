import React from "react";
import "../../../../Assets/css/halaStyle.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import Ring from "../../../../Assets/images/Ring.png";
import Studs from "../../../../Assets/images/Studs.png";

const Ringbuilderhome = () => {
  const history = useHistory();
  return (
    <>
      <div className="rcs_ringbuilder_home py-5">
        <Container className="rcs_custom_home_container">
          
          <Row className="justify-content-center">
            <Col xxl={9}>
            <Row>
            <Col sm={12} md={12} lg={6} className="hl_margin_bottom_box">
              <div className="shadow  pb-4 pt-md-0 pt-4 px-4 h-100">
                <div className="hl_ring_builder_img text-center">
        
                  <Image className="img-fluid" src={Ring} alt="" />
                </div>

                <div class="ddov_eng_ring_text_box text-center">
                  <h5>Create Your Dream</h5>
                  <h3>Diamond Ring</h3>
                  <p>
                    Design a Diamond Ring That Reflects Your Style and
                    Personality.
                  </p>
                  <div class="hl_ring_builder_btn_box ">
                    <button
                      type="button"
                      class="ddov_btn_gray btn btn-primary"
                      onClick={() => history.push("/ringsettings")}
                    >
                      Start with a Setting{" "}
                      <span>
                        <BsArrowRight />
                      </span>
                    </button>
                    <button
                      type="button"
                      class="ddov_btn_outline btn btn-primary"
                      onClick={() => history.push("/diamonds")}
                    >
                      Start with a Diamond{" "}
                      <span>
                        <BsArrowRight />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Col>

            <Col sm={12} md={12} lg={6} className="hl_margin_bottom_box">
              <div className="shadow  pb-md-4 pt-md-0 pt-4 px-4 mt-md-0 mt-4 h-100">
                <div className="hl_ring_builder_img text-center">
                  <Image className="img-fluid" src={Studs} alt="" />
                </div>

                <div class="ddov_eng_ring_text_box text-center">
                  <h5>Create Your Dream</h5>
                  <h3>Diamond Studs</h3>
                  <p>
                    Design a Diamond Studs That Reflects Your Style and
                    Personality.
                  </p>
                  <div class="hl_ring_builder_btn_box ">
                    <button
                      type="button"
                      class="ddov_btn_gray btn btn-primary"
                      onClick={() => history.push("/stud-settings/diamonds")}
                    >
                      Start with a Setting{" "}
                      <span>
                        <BsArrowRight />
                      </span>
                    </button>
                    {/* <div className="hl_text_or"><p>Or</p></div> */}
                    <button
                      type="button"
                      class="ddov_btn_outline btn btn-primary"
                      onClick={() => history.push("/stud-diamonds")}
                    >
                      Start with a Diamond{" "}
                      <span>
                        <BsArrowRight />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            </Row>
            </Col>
          
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Ringbuilderhome;
