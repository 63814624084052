import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Col,
  Container,
  Image,
  Nav,
  Row,
  Form,
  Dropdown,
  Modal,
  Accordion,
  Button,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
// import "../../Assets/css/MegaMenu.css"
import "../../Assets/css/halaHeader.css";
import logoImage from "../../Assets/images/hala-london-img/hala-logo1.png";
import SearchIcon from "@mui/icons-material/Search";
import User1 from "../../Assets/images/blue-user.png";
import { useHistory } from "react-router-dom";
import { Drawer } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import loaderImg from "../../Assets/images/preloader.gif";
import menuImages from "../../Assets/images/menu.png";
import {
  Email_details,
  base_url,
  client_name,
  contact_number,
} from "../../Helpers/request";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import "../../../node_modules/react-slidedown/lib/slidedown.css";
import { BiHeart } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import Heart1 from "../../Assets/images/blue-heart.png";
import Heart2 from "../../Assets/images/blue-heart.png";
import Cart1 from "../../Assets/images/blue-bag.png";
import Cart2 from "../../Assets/images/blue-bag.png";
import Magnify0 from "../../Assets/images/search1.png";
import Magnify1 from "../../Assets/images/search1.png";
import { useDispatch, useSelector } from "react-redux";
import { BsFillChatFill, BsFillTelephoneFill } from "react-icons/bs";
import { IoMdMail, IoMdContact } from "react-icons/io";
import { img_url } from "../../Helpers/request";
import { HiOutlineLocationMarker, HiOutlineShoppingBag } from "react-icons/hi";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { whishlistlength } from "../../Reducer/wishlistReducer";
import { cartlengthvalue } from "../../Reducer/cartReducer";
import { diamondpreloader, sessionId } from "../../Reducer/homeReducer";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const MegaMenu = () => {
  const isLogin = JSON.parse(localStorage.getItem("bw-user"))
    ? JSON.parse(localStorage.getItem("bw-user")).isLogin
    : false;

  const contact_number_link = contact_number.link;
  const Email_details_link = Email_details.link;
  const menudata = useSelector((state) => state.persistedReducer.home.menu);
  const [submenu, setSubmenu] = useState([]);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const [
    isScrollValueMoreThanHeaderHeight,
    setIsScrollValueMoreThanHeaderHeight,
  ] = useState(false);
  const [dropdown, setDropdown] = useState({
    img: localStorage.getItem("bw-currency_img"),
    icon: localStorage.getItem("bw-currencyicon"),
    code: localStorage.getItem("bw-currency"),
  });

  const [topHeader, setTopHeader] = useState(true);
  const [left, setLeft] = useState(false);
  const [hover, setHover] = useState(0);
  const [hoverindex, setHoverindex] = useState(false);
  const [search_text, setSearch_text] = useState("");
  const [search_show, setSearch_show] = useState(false);
  const [wishover, setWishover] = useState(false);
  const [carthover, setCarthover] = useState(false);

  const [searchhover, setSearchhover] = useState(false);

  const [currencydata, setCurrencydata] = useState([]);
  const cartlength = useSelector(
    (state) => state.persistedReducer.cart.cartlength
  );
  const wishlistlength = useSelector(
    (state) => state.persistedReducer.wish.whishlistlength
  );
  const ispreloader = useSelector(
    (state) => state.persistedReducer.home.diamondpreloader
  );
  const [preloader, setpreloader] = useState(false);
  const urls = window.location.pathname === "/" ||
               window.location.pathname === "/login" ||
               window.location.pathname === "/register" ||
               window.location.pathname.includes("/productdetail") ||
               window.location.pathname.includes("/diamonds-details") ||
               window.location.pathname.includes("/ringsettingdetail") ||
               window.location.pathname.includes("/diamonds") ||
               window.location.pathname.includes("/ringsettings") ||
               window.location.pathname.includes("/jewelry") ||
               window.location.pathname.includes("/fancy-color-diamond") ||
               window.location.pathname.includes("/ringsettings/fancycolor") ||
               window.location.pathname.includes("/cart") ||
               window.location.pathname.includes("/checkout-shipping") 
    // window.location.pathname.includes("/stud-settings") ||
    // window.location.pathname.includes("/stud-settingdetail") ||
    // window.location.pathname.includes("/stud-diamonds") ||
    // window.location.pathname.includes("/stud-diamonds-details");

  useEffect(() => {
    if (!urls) dispatch(diamondpreloader(true));
    setTimeout(() => {
      // setpreloader(false);
      if (!urls) dispatch(diamondpreloader(false));
    }, 1000);
    document.body.className = window.location.pathname;
    // return () => {
    //   document.body.className = '';
    // };
  }, [window.location.pathname]);
  const toggleDrawer = (open) => {
    setLeft(open);
  };
  const classes = useStyles();
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 15);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "en,es,fr,de,iw,ja,zh-CN,sv,tr,ar,hi",
        gaTrack: true,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  useLayoutEffect(() => {
    //---------------------------- currency api
    axios
      .get(base_url + "/common/currency")
      .then((res) => {
        if (res.data.status == 1) {
          setCurrencydata(res.data.data);
          localStorage.getItem("bw-currency")
            ? localStorage.getItem("bw-currency")
            : localStorage.setItem(
                "bw-currency",
                res.data.data[0]?.currency_code
              );
          localStorage.getItem("bw-currency_img")
            ? localStorage.getItem("bw-currency_img")
            : localStorage.setItem(
                "bw-currency_img",
                res.data.data[0]?.country_flag
              );
          localStorage.getItem("bw-currencyicon")
            ? localStorage.getItem("bw-currencyicon")
            : localStorage.setItem(
                "bw-currencyicon",
                res.data.data[0]?.currency_icon
              );
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const currencyHandle = async (code, img, icon) => {
    setDropdown({ img: img, icon: icon, code: code });
    localStorage.removeItem("bw-fancycolordata");
    localStorage.removeItem("bw-gemstonedata");
    localStorage.removeItem("bw-diamonddata");
    localStorage.removeItem("bw-settingdata");
    localStorage.setItem("bw-currency", code);
    localStorage.setItem("bw-currency_img", img);
    localStorage.setItem("bw-currencyicon", icon);
    sessionStorage.removeItem("bw-listfilter");
    sessionStorage.removeItem("bw_f_filter");
    sessionStorage.removeItem("bw_s_filter");
    sessionStorage.removeItem("bw_d_filter");
    sessionStorage.removeItem("bw_g_filter");
    sessionStorage.removeItem("bw-list-category");
    if (window.location.pathname == "/complete-diamond-ring") {
      history.push("/diamonds");
    } else if (window.location.pathname == "/complete-gemstone-ring") {
      history.push("/gemstones");
    } else if (window.location.pathname == "/complete-fancycolor-ring") {
      history.push("/fancy-color-diamond");
    }
    window.location.reload();
  };

  const signin = () => {
    setLogin(true);
    setForgotpassword(false);
  };
  const handleClicktop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosetop = () => {
    setAnchorEl(null);
  };
  // const [anchorEl, setAnchorEl] = useState(null);
  /*search code*/
  const [forgotpassword, setForgotpassword] = useState(false);
  //login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //forgotpass
  const [f_email, setF_email] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [login, setLogin] = useState(false);
  const user = JSON.parse(localStorage.getItem("bw-user"))
    ? JSON.parse(localStorage.getItem("bw-user"))
    : [];
  // logout start
  const dispatch = useDispatch();
  const handlelogout = () => {
    localStorage.removeItem("bw-user");
    dispatch(whishlistlength(0));
    dispatch(cartlengthvalue(0));
    dispatch(sessionId(""));
    setAnchorEl(null);
    history.push("/");
    window.location.reload(true);
  };
  return (
    <>
     {ispreloader && (
        <div className="hala_preloader position-fixed w-100 h-100 text-center d-flex align-items-center justify-content-center">
          <img
            src={loaderImg}
            alt="Loader Image"
            className="dfl_loader_diamond_img"
          />
        </div>
      )}

      <div className="rcs_header_seaction" id="rcs_header_seaction">
        {/* <div className="rcs_header_seaction" id="rcs_header_seaction"> */}
        {/* Top Header Section */}
        {topHeader ? (
          <div className="rcs_top_header">
            <Container className="showcase-container rcs_custom_home_container">
              <div className="rcs_top_head_content">
                <Row className="w-100 m-auto d-flex align-items-center">
                  <Col
                    xs={12}
                    md={9}
                    lg={4}
                    className="p-0 d-none d-sm-none d-lg-block"
                  >
                    <div className="rcs_top_head_content_right hl_m_content_left">
                      <ul>
                        <li
                          onClick={(e) => {
                            window.location.href = contact_number.link;
                          }}
                        >
                          <span>
                            <BsTelephone />
                          </span>{" "}
                          {contact_number.text}{" "}
                        </li>
                        {/* <li
                          onClick={(e) => {
                            window.location.href = Email_details.link;
                          }}
                        >
                          <span>
                            <BsEnvelope />
                          </span>{" "}
                          {Email_details.text}{" "}
                        </li> */}
                      </ul>
                    </div>
                  </Col>
                  {isMobile ? (
                    ""
                  ) : (
                    <Col
                      xs={12}
                      md={6}
                      lg={4}
                      className="p-0 d-none d-sm-none d-lg-block"
                    >
                      <p>Free shipping on all UK orders</p>
                    </Col>
                  )}
                  <Col xs={12} md={12} lg={4} className="p-0 d-flex align-items-center justify-content-md-center justify-content-lg-end  justify-content-sm-center text-center">
                    
                    <div className="rcs_top_head_content_right hl_m_content_right">
                      <ul className=" d-flex justify-content-sm-center justify-content-center  justify-content-md-center justify-content-lg-end align-items-center  h-100 mt-1">
                        <li>
                          <Link to="/contact" className="text-dark">
                            <span>
                              <HiOutlineLocationMarker />
                            </span>{" "}
                            Visit Showroom
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div
                      id="google_translate_element"
                      className="float-right mx-2"
                    ></div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        ) : (
          ""
        )}
        {/* Top Header Section */}
        <div
          className={
            isScrollValueMoreThanHeaderHeight
              ? "rcs_logo_head rcs_sticky-top sticky-top"
              : "rcs_logo_head"
          }
        >
          {/* Header Section */}
          <div className="rcs_header">
            <Container fluid className="p-0">
              <Row className="w-100 m-auto">
                <Col sm={12} md={12} lg={12} xs={12} className="rcs_large_Tab1">
                  {/* Menu Section */}
                  <div className="mg_main_d">
                    <div className="mg_menu_logo_main_div">
                      <Nav className="m-auto rcs_mobile_header_menu">
                        <Image
                          onClick={() => {
                            toggleDrawer(true);
                            dispatch(diamondpreloader(false));
                          }}
                          src={menuImages}
                          alt="menu icon"
                          width="30px"
                        ></Image>
                      </Nav>
                      <div className="rcs_logo_section">
                        {/* <NavLink to="/"> */}
                        <Image
                          onClick={() => history.push("/")}
                          src={logoImage}
                          alt={client_name.c_name}
                        ></Image>
                        {/* </NavLink> */}
                      </div>
                      <div className="rcs_right_side rcs_right_side_mobile1 d-lg-none">
                        <ul>
                          <li className="rcs_currency">
                            <Dropdown className="d-inline rcs_dropdown_lang">
                              <Dropdown.Toggle id="dropdown-autoclose-true">
                                <Image
                                  src={dropdown?.img}
                                  alt={dropdown?.code}
                                  style={{ width: "25px" }}
                                ></Image>{" "}
                                <span>{dropdown?.code}</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {currencydata.map((val) => (
                                  <Dropdown.Item
                                    onClick={() => {
                                      currencyHandle(
                                        val.currency_code,
                                        val.country_flag,
                                        val.currency_icon
                                      );
                                    }}
                                    href="#"
                                  >
                                    <Image
                                      src={val.country_flag}
                                      alt={val.currency_code}
                                    ></Image>
                                    <span> {val.currency_code}</span>
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                          <li>
                            <BiSearch
                              onClick={() => setSearch_show(!search_show)}
                            />
                          </li>
                          <li>
                            {/* <Login />sdfgfdg */}
                            {isLogin ? (
                              <>
                                <Button
                                  className="rcs_blank_button rcs_after_login_button"
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={handleClicktop}
                                >
                                  {isMobileOnly ? (
                                    <span className="rcs_mobile">
                                      {user.first_name[0]}
                                    </span>
                                  ) : isTablet ? (
                                    <span className="rcs_mobile">
                                      {user.first_name[0]}
                                    </span>
                                  ) : (
                                    <span className="rcs_mobile">
                                      {user.first_name[0]}{" "}
                                      <KeyboardArrowDownIcon />
                                    </span>
                                  )}
                                </Button>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  className="rcs_menu_list_item"
                                  open={Boolean(anchorEl)}
                                  onClick={handleClosetop}
                                >
                                  <MenuItem disabled>
                                    {" "}
                                    <Image
                                      src={User1}
                                      alt="new login Image"
                                      width="15px"
                                    />{" "}
                                    {user.first_name}
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      history.push("/account/myaccount")
                                    }
                                  >
                                    My Account
                                  </MenuItem>
                                  <MenuItem onClick={handlelogout}>
                                    Logout
                                  </MenuItem>
                                </Menu>
                              </>
                            ) : (


                              
<svg  className="rcs_wishlist_icon"
                                onClick={() =>
                                  !isLogin
                                    ? history.push("/login")
                                    : history.push("/")
                                } width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.9809 19.1827C10.1464 19.1827 6.01782 19.9137 6.01782 22.8411C6.01782 25.7685 10.1202 26.5256 14.9809 26.5256C19.8154 26.5256 23.9428 25.7935 23.9428 22.8673C23.9428 19.9411 19.8416 19.1827 14.9809 19.1827Z" stroke="#0A1857" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.9809 15.0074C18.1535 15.0074 20.7249 12.4348 20.7249 9.26214C20.7249 6.08953 18.1535 3.5181 14.9809 3.5181C11.8082 3.5181 9.23563 6.08953 9.23563 9.26214C9.22491 12.424 11.7797 14.9967 14.9404 15.0074H14.9809Z" stroke="#0A1857" stroke-width="1.42857" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                              // <Image
                              //   src={User1}
                              //   alt="new login Image"
                              //   className="rcs_wishlist_icon"
                              //   onClick={() =>
                              //     !isLogin
                              //       ? history.push("/login")
                              //       : history.push("/")
                              //   }
                              // />
                            )}
                          </li>
                          <li
                            className="d-none"
                            onClick={() =>
                              isLogin
                                ? history.push("/account/wishlist")
                                : history.push("/wishlist")
                            }
                          >
                            <BiHeart className="rcs_cart_mobile_icon1" />{" "}
                            {wishlistlength && wishlistlength != "0" ? (
                              <span className="rcs_wish_badge">
                                {wishlistlength}
                              </span>
                            ) : (
                              ""
                            )}{" "}
                          </li>
                          <li
                            onClick={() => {
                              history.push("/cart");
                            }}
                          >
                            <HiOutlineShoppingBag className="rcs_cart_mobile_icon" />{" "}
                            {cartlength && cartlength != "0" ? (
                              <span className="rcs_cart_badge">
                                {cartlength}
                              </span>
                            ) : (
                              ""
                            )}{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="rcs_menu menu_list_main">
                      <Container className="rcs_custome_container1">
                        <Row>
                          <nav id="rcs_menu_header_top">
                            <ul className="rcs_header_menu">
                              {menudata?.map((value, index) => (
                                <li
                                  className="rcs_top_header_menu"
                                  onMouseEnter={() => {
                                    setHover(1);
                                    setSubmenu(value);
                                  }}
                                  onMouseLeave={() => setHover(0)}
                                >
                                  <h6 className="1st">
                                    <NavLink to={value?.slug}>
                                      {value?.name}
                                    </NavLink>
                                  </h6>
                                  {value?.design == 5 ? (
                                    <ul
                                      className="rcs_top_header_menu_dropdown"
                                      onMouseEnter={() => setHover(1)}
                                      onMouseLeave={() => setHover(0)}
                                    >
                                      {submenu?.submenu?.map((val) => (
                                        <li className="dir rcs_top_header_menu1">
                                          <span>
                                            <NavLink to={val?.slug}>
                                              {val.name}
                                            </NavLink>
                                          </span>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              ))}
                            </ul>
                          </nav>
                        </Row>
                      </Container>
                    </div>

                    <div className="mg_menu_right_side_area">
                      <div className="rcs_right_side rcs_right_side_mobile d-none d-md-none d-lg-block">
                        <ul>
                          <li
                            className=""
                            onClickAway={() => setSearch_show(false)}
                          >
                            <img
                              onClick={() => setSearch_show(!search_show)}
                              onMouseOver={() => setSearchhover(true)}
                              onMouseLeave={() => setSearchhover(false)}
                              className="rcs_wishlist_icon"
                              src={searchhover ? Magnify1 : Magnify0}
                            />
                            <Modal
                              className={
                                isScrollValueMoreThanHeaderHeight
                                  ? "rcs_search_modal12"
                                  : "rcs_search_modal"
                              }
                              show={search_show}
                              onHide={() => setSearch_show(false)}
                            >
                              <div className="rcs_search_bar">
                                <Form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    history.push("/search/" + search_text);
                                    window.scrollTo(0, 0);
                                    setSearch_text("");
                                    setSearch_show(false);
                                  }}
                                >
                                  <SearchIcon
                                    onClick={(e) => {
                                      history.push("/search/" + search_text);
                                      setSearch_text("");
                                      setSearch_show(false);
                                    }}
                                  />
                                  <Form.Control
                                    type="text"
                                    placeholder="Search for.."
                                    value={search_text}
                                    onChange={(e) =>
                                      setSearch_text(e.target.value)
                                    }
                                  />
                                  <AiOutlineClose
                                    onClick={() => setSearch_show(!search_show)}
                                  />
                                </Form>
                              </div>
                            </Modal>
                          </li>
                          <li className="d-none d-lg-block">
                            {/* <Login />sdfgfdg */}
                            {isLogin ? (
                              <>
                                <Button
                                  className="rcs_blank_button rcs_after_login_button"
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={handleClicktop}
                                >
                                  {isMobileOnly ? (
                                    <span className="rcs_mobile">
                                      {user.first_name[0]}
                                    </span>
                                  ) : isTablet ? (
                                    <span className="rcs_mobile">
                                      {user.first_name[0]}
                                    </span>
                                  ) : (
                                    <span className="rcs_mobile">
                                      {user.first_name[0]}{" "}
                                      <KeyboardArrowDownIcon />
                                    </span>
                                  )}
                                </Button>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  className="rcs_menu_list_item"
                                  open={Boolean(anchorEl)}
                                  onClick={handleClosetop}
                                >
                                  <MenuItem disabled>
                                    <Image
                                      src={User1}
                                      alt="new login Image"
                                      width="18px"
                                    />{" "}
                                    {user.first_name}
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      history.push("/account/myaccount")
                                    }
                                  >
                                    My Account
                                  </MenuItem>
                                  <MenuItem onClick={handlelogout}>
                                    Logout
                                  </MenuItem>
                                </Menu>
                              </>
                            ) : (
                              <Image
                                src={User1}
                                alt="new login Image"
                                className="rcs_wishlist_icon"
                                onClick={() =>
                                  !isLogin
                                    ? history.push("/login")
                                    : history.push("/")
                                }
                              />
                                
                            )}
                          </li>
                          <li
                            className="d-none d-lg-block"
                            onClick={() =>
                              isLogin
                                ? history.push("/account/wishlist")
                                : history.push("/wishlist")
                            }
                          >
                            <img
                              onMouseOver={() => setWishover(true)}
                              onMouseLeave={() => setWishover(false)}
                              className="rcs_wishlist_icon"
                              src={wishover ? Heart2 : Heart1}
                            />
                            {wishlistlength && wishlistlength != "0" ? (
                              <span className="rcs_wish_badge">
                                {wishlistlength}
                              </span>
                            ) : (
                              ""
                            )}
                          </li>
                          <li
                            className="d-none d-lg-block"
                            onClick={() => {
                              history.push("/cart");
                            }}
                          >
                            <img
                              onMouseOver={() => setCarthover(true)}
                              onMouseLeave={() => setCarthover(false)}
                              className="rcs_wishlist_icon"
                              src={carthover ? Cart2 : Cart1}
                            />
                            {cartlength && cartlength != "0" ? (
                              <span className="rcs_cart_badge">
                                {cartlength}
                              </span>
                            ) : (
                              ""
                            )}
                          </li>
                          <li className="d-none">
                            <Dropdown className="d-inline rcs_dropdown_lang">
                              <Dropdown.Toggle id="dropdown-autoclose-true">
                                <Image
                                  src={dropdown?.img}
                                  style={{ width: "25px" }}
                                ></Image>
                                <span>{dropdown?.code}</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {currencydata.map((val) => (
                                  <Dropdown.Item
                                    onClick={() => {
                                      currencyHandle(
                                        val.currency_code,
                                        val.country_flag,
                                        val.currency_icon
                                      );
                                    }}
                                    href="#"
                                  >
                                    <Image
                                      src={val.country_flag}
                                      alt={val.currency_code}
                                    ></Image>
                                    <span> {val.currency_code}</span>
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                          <li>
                            <div className="hl_book_an_appointment_box_outer">
                              <button
                                onClick={() =>
                                  history.push("/make-an-appointment")
                                }
                              >
                                Book Appointment
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/*mega menu */}
                  <div className="menu_item_main">
                    {submenu?.design != 5 ? (
                      <div
                        onMouseEnter={() => setHover(1)}
                        onMouseLeave={() => setHover(0)}
                        className={
                          hover == "1" && submenu?.submenu?.length
                            ? "rcs_top_header_menu_dropdown mega_menu_content_main visible bw-" +
                              submenu?.name?.replaceAll(" ", "-")
                            : "rcs_top_header_menu_dropdown mega_menu_content_main invisible"
                        }
                      >
                        <div className="rcs_megaMenu d-block">
                          <div className="rcs_megaMenu_inner_top">
                            <div className="rcs_megaMenu_inner">
                              <Container fluid className="p-0">
                                <Row className="w-100 m-auto  justify-content-center">
                                  {submenu?.submenu?.map((val, index) => (
                                    <>
                                      <Col
                                        lg={val?.col}
                                        xl={val?.col}
                                        className="pl-0"
                                      >
                                        {val?.submenu?.map((val1) =>
                                          val1?.design == "1" ? (
                                            <div
                                              className={
                                                "rcs_diamonds_content mj_daimond_d_font bw-" +
                                                val1?.name?.replaceAll(" ", "-")
                                              }
                                            >
                                              <div className="mega-list-cat">
                                                <h2 className="title14 font-bold text-uppercase mtb">
                                                  {val1?.name}
                                                </h2>
                                                <ul>
                                                  {val1?.submenu?.map(
                                                    (val2) => (
                                                      <li>
                                                        {val2?.design == 4 ? (
                                                          <Link
                                                            to="#"
                                                            onClick={() =>
                                                              window.open(
                                                                img_url +
                                                                  val2?.slug,
                                                                "_target"
                                                              )
                                                            }
                                                          >
                                                            {val2?.img_url
                                                              ?.length ? (
                                                              <img
                                                                src={
                                                                  val2?.img_url
                                                                }
                                                                className="img-size"
                                                                alt={
                                                                  val2?.alt_tag
                                                                }
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                            {val2?.name}
                                                          </Link>
                                                        ) : (
                                                          <Link to={val2?.slug}>
                                                            {val2?.img_url
                                                              ?.length ? (
                                                              <img
                                                                src={
                                                                  val2?.img_url
                                                                }
                                                                className="img-size"
                                                                alt={
                                                                  val2?.alt_tag
                                                                }
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                            {val2?.name}
                                                          </Link>
                                                        )}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          ) : val1?.design == "2" ? (
                                            <div
                                              className={
                                                "rcs_diamonds_content dfL-border_rel bw-" +
                                                val1?.name?.replaceAll(" ", "-")
                                              }
                                            >
                                              <h2> {val1?.name} </h2>
                                              <div className="m-auto w-100 row">
                                                {val1?.submenu?.map((val2) => (
                                                  <div className="cust_padd_diamonds_shape col-lg-6">
                                                    <div className="rcs_diamonds_content_inner">
                                                      <Link to={val2?.slug}>
                                                        {val2?.img_url
                                                          ?.length ? (
                                                          <img
                                                            src={val2?.img_url}
                                                            alt={val2?.alt_tag}
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        <p>{val2?.name}</p>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          ) : val1?.design == "3" ? (
                                            <div className="rcs_diamonds_content">
                                              <Link to={val1?.slug}>
                                                {val1?.img_url?.length ? (
                                                  <img
                                                    src={val1?.img_url}
                                                    alt={val1?.alt_tag}
                                                    className="img-fluid dfl_ddiamond_img"
                                                    style={{
                                                      height: "100%",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </Link>
                                            </div>
                                          ) : (
                                            ""
                                          )
                                        )}
                                        {index < 2 ? (
                                          <div className="dfl_menu_border">
                                            {" "}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </>
                                  ))}
                                </Row>
                              </Container>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* menu Section */}
                </Col>
              </Row>
            </Container>
          </div>
          {/* Header Section */}

          {hover == "1" && submenu?.submenu?.length ? (
            <div className="hover_overlayer"></div>
          ) : (
            ""
          )}
        </div>
        {/* Mobile Menu Section */}
        <div className="rcs_menu_mobile d-none">
          <Container>
            <Row>
              <Drawer
                anchor="left"
                open={left}
                onClose={() => toggleDrawer(false)}
              >
                <div role="presentation" onKeyDown={() => toggleDrawer(false)}>
                  <div
                    className={
                      show === true ? "mobile-menu" : "mobile-menu nav-open"
                    }
                  >
                    <div className="inner-mobile-menu">
                      <Accordion defaultActiveKey="0">
                        {/* <Accordion.Item eventKey="0">
                                <Accordion.Header><Link to="#"><span className='auth'><img src={User} alt="" /></span> LOGIN/SIGNUP</Link></Accordion.Header>
                            </Accordion.Item> */}
                        {menudata?.map((val, index) => (
                          <Accordion.Item eventKey={index}>
                            <Accordion.Header
                              className={`${
                                val.name === ("Custom Design" || "Blog" || "Wishlist")
                                  ? "no_button"
                                  : ""
                              }`}
                            >
                              <Link
                                to={val.slug}
                                onClick={() => toggleDrawer(false)}
                              >
                                {val.name}
                              </Link>
                            </Accordion.Header>
                            <Accordion.Body>
                              {val?.submenu?.map((val1) =>
                                val1?.submenu?.map((val2) =>
                                  val2?.design == 3 ? (
                                    <img
                                      className="w-100 mt-2"
                                      onClick={() => history.push(val2?.slug)}
                                      src={val2?.img_url}
                                      alt={val2?.alt_tag}
                                    />
                                  ) : (
                                    <>
                                      <h2 className="title">{val2?.name}</h2>
                                      <ul
                                        className={
                                          val2?.design == 2
                                            ? "two-col-list"
                                            : "single-col-list"
                                        }
                                      >
                                        {val2?.submenu?.map((val3) => (
                                          <li>
                                            <Link
                                              onClick={() =>
                                                toggleDrawer(false)
                                              }
                                              to={val3.slug}
                                            >
                                              {val3?.img_url ? (
                                                <img
                                                  src={val3?.img_url}
                                                  alt={val3?.alt_tag}
                                                />
                                              ) : (
                                                ""
                                              )}{" "}
                                              {val3?.name}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </>
                                  )
                                )
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                        <Accordion.Item
                          eventKey="8"
                          onClick={() => toggleDrawer(false)}
                        >
                          <Accordion.Header
                            className="no_button"
                            onClick={() => history.push("/wishlist")}
                          >
                            Wishlist
                          </Accordion.Header>
                        </Accordion.Item>
                        <Accordion.Item
                          eventKey="9"
                          onClick={() => toggleDrawer(false)}
                        >
                          <Accordion.Header
                            className="no_button"
                            onClick={() => history.push("/make-an-appointment")}
                          >
                            Book Appointment
                          </Accordion.Header>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7" className="contactDetail">
                          <Accordion.Header>Contact us</Accordion.Header>
                          <div className="contact_detail">
                            <ul>
                             
                              <li>
                                <a
                                  onClick={() => {
                                    setShow(false);
                                    window.location.href = contact_number.link;
                                  }}
                                  href="#"
                                >
                                  <span>
                                    <BsFillTelephoneFill />
                                  </span>{" "}
                                  Call
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={(e) => {
                                    setShow(false);
                                    window.location.href = Email_details.link;
                                  }}
                                >
                                  <span>
                                    <IoMdMail />
                                  </span>{" "}
                                  Email
                                </a>
                              </li>
                              <li  onClick={() => toggleDrawer(false)}>
                                <Link
                                  onClick={() => setShow(false)}
                                  to="/contact"
                                >
                                  <span>
                                    <IoMdContact />
                                  </span>{" "}
                                  Contact
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </Drawer>
            </Row>
          </Container>
        </div>
        {/* Mobile Menu Section */}
      </div>
    </>
  );
};
export default MegaMenu;
