import React from "react";
import "../../../../Assets/css/halaStyle.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import DiamondRing from "../../../../Assets/images/hala-london-img/ring-img.png";
import Necklaces from "../../../../Assets/images/hala-london-img/necklaces-img.png";
import Earrings from "../../../../Assets/images/hala-london-img/earrings-img.png";
import Pendants from "../../../../Assets/images/hala-london-img/pendants-img.png";
import Bracelets from "../../../../Assets/images/hala-london-img/bracelets-img.png";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const HalaCollection = () => {
  const history = useHistory();
  const collectionData = [
    {
      collectionimg: DiamondRing,
      collectioniName: "Diamond Ring",
      collectionLink: "/jewelry/fashion-rings",
    },
    {
      collectionimg: Necklaces,
      collectioniName: "Necklaces",
      collectionLink: "/jewelry/necklaces",
    },
    {
      collectionimg: Bracelets,
      collectioniName: "Bracelets",
      collectionLink: "/jewelry/bracelets",
    },
    {
      collectionimg: Pendants,
      collectioniName: "Pendants",
      collectionLink: "/jewelry/pendants",
    },
    {
      collectionimg: Earrings,
      collectioniName: "Earrings",
      collectionLink: "/jewelry/earrings",
    },
  ];

  const settingsCollection = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    arrows:false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="hl_collection_section">
      <Container fluid>
    
          {isMobile ? (
                <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <div className="hl_collection_mobileview">
                <Slider {...settingsCollection}
                  className="hl_collection_slider_mobileview"
                >
                  {collectionData.map((val, index) => (
                    <div className="hl_collection_img_box_outer">
                      <div className="hl_collection_img_box">
                        <Image
                          src={val.collectionimg}
                          alt={val.collectioniName}
                          className="img-fluid"
                          onClick={() => history.push(val.collectionLink)}
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>{" "}
            </Col>
            </Row>
          ) : (
            <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <div className="hl_Collectiom_list hl_collection_desktop_view">
                <ul>
                  {collectionData.map((val, index) => (
                    <li>
                      <div className="hl_collection_img_box">
                        <Image
                          src={val.collectionimg}
                          alt={val.collectioniName}
                          className="img-fluid"
                          onClick={() => history.push(val.collectionLink)}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            </Row>
          )}
       
      </Container>
    </div>
  );
};

export default HalaCollection;
