import { Button } from "@mui/material";
import React from "react";
import "../../../../Assets/css/halaStyle.css";
import { Col, Container, Image, Row } from "react-bootstrap";
// import ringSetting from "../../Assets/images/home/ring-setting.webp";
// import settingDiamonds from "../../Assets/images/home/setting-diamonds.webp";
// import finalRing from "../../Assets/images/home/final-ring.webp";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BsArrowRight } from "react-icons/bs";
import { isMobileOnly } from "react-device-detect";
// import RingVideo from "../../Assets/ring-video1.mp4";

const StudsBuilder = () => {
  const history = useHistory();
  return (
    <>
        <div className="rcs_ringbuilder_home">
      <Container className="hl_custome_container  rcs_studs_builder">
         {/*  <Row className="w-100 m-auto">
            {isMobileOnly ? "" :
            <Col sm={12} md={5} lg={5}>
              <div className="hl_ring_builder_img"></div>
            </Col>
                 }
            <Col sm={12} md={{span:7, offset:5}} lg={{span:7, offset:5}} className="d-flex justify-content-center  mobile_align_end"> */}
              <div class="ddov_eng_ring_text_box">
                <h5>Create Your Own</h5>
                <h3>Diamond Studs</h3>
                <p>
                  Design Diamond Studs That Reflects Your Style and Personality.
                </p>
                <div class="hl_ring_builder_btn_box">
                  <button
                    type="button"
                    class="ddov_btn_gray btn btn-primary"
                    onClick={() => history.push("/stud-diamonds")}
                  >
                    Create Diamond Studs{" "}
                    <span>
                      <BsArrowRight />
                    </span>
                  </button>
          
                </div>
              </div>
            {/* </Col>
          </Row> */}
      </Container>
        </div>
    </>
  );
};

export default StudsBuilder;
