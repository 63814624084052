import React from "react";
import { Form, Image, Modal } from "react-bootstrap";
import "../../../Assets/css/myaccount.css";
import {  FormGroup,  } from "@material-ui/core";
import { Col, Container, Row } from "react-bootstrap";
import Button from "@mui/material/Button";
import axios from "axios";
import { base_url } from "../../../Helpers/request";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import Loader from "../../../Components/Loader";
import { useSelector } from "react-redux";
import blackCheck from "../../../Assets/images/black-check.png";
import WhiteCheck from "../../../Assets/images/white-check.png";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const WholesalerRegister = () => {
  const history = useHistory();
  const [value, setValue] = React.useState("1");
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [address1, setAddress1] = React.useState("");
  const [address2, setAddress2] = React.useState("");
  const [postalcode, setPostalcode] = React.useState("");
  const [state, setState] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [city, setCity] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [selectedType, setSelectedType] = React.useState("");
  const [vat, setVat] = React.useState("");
  const [other, setOther] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const session_Id = useSelector(
    (state) => state.persistedReducer.home.session_id
  );

  const submitRegisterWholesaler = (e) => {
    e.preventDefault();
    setLoader(true);
    const data = {
      username: "",
      first_name: firstname,
      last_name: lastname,
      session_id: session_Id,
      email: email,
      phone: phone,
      company: company,
      address1: address1,
      address2: address2,
      zip: postalcode,
      state: state,
      country: country,
      city: city,
      password: password,
      website: website,
      company_type: selectedType,
      company_specification: other,
      vat_number: vat,
    };
    axios
      .post(base_url + "/user/registerwholesaler", data, {
        headers: {
          // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        if (response.data.status == 1) {
          setLoader(false);
          await swal(response.data.message, "", "success");
          var user = {
            token: response.data.data.token,
            user_id: response.data.data.user_id,
            email: response.data.data.email,
            first_name: response.data.data.first_name,
            last_name: response.data.data.last_name,
            isLogin: true,
          };
          setFirstname("");
          setLastname("");
          setEmail("");
          setPhone("");
          setCompany("");
          setAddress1("");
          setAddress2("");
          setPostalcode("");
          setState("");
          setCountry("");
          setCity("");
          setWebsite("");
          setSelectedType("");
          setVat("");
          setOther("");
          setPassword("");

          // localStorage.setItem("bw-user", JSON.stringify(user));
          window.location.replace("/login");
        } else {
          setLoader(false);
          return swal(response.data.message, "", "error");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  const metaDetails = useSelector((state) => state.persistedReducer.meta.meta);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description}></meta>
        <meta name="keywords" content={metaDetails.keywords}></meta>
      </Helmet>
      <div className="rcs_myaccount_section hl_new_register_section">
        <Container>
          {/* <Row>
                        <Col className="rcs_breadcrumb mb-2">
                            <Breadcrumbs aria-label="breadcrumb">
                                <NavLink underline="hover" color="inherit" to="/">
                                    Home
                                </NavLink>
                                <Typography color="text.primary">Register</Typography>
                            </Breadcrumbs>
                        </Col>
                    </Row> */}
          <Row>
            <Col xs={12} sm={12} md={{ span: 6, offset: 3 }}>
              <din className="rcs_top_heading text-left">
                <h1 className="Font_saintecolombe text-left">
                  Create an Wholeslaer / Jeweller account
                </h1>
                <p className="mb-0 text-left pb-3">
                  If you're a Jeweller or diamond trader, register now for a
                  professional account to access the world's largest stock of
                  color diamonds at wholesale prices.{" "}
                </p>
                <p>
                  Your account will have to be approved by Hala London Diamonds
                  before you can access it.
                </p>
                {/* <NavLink
                  to="#"
                  className="text-dark text-decoration-underline text-left"
                >
                  Register as wholesaler
                </NavLink> */}
              </din>
            </Col>
          </Row>
          <Row className="rcs_tab_section m-auto w-100">
            <Col className="p-0">
              <div className="rcs_register_content mt-2 pt-2">
                <Row className="m-auto w-100">
                  {/* <Col xs={12} sm={8} md={4}>
                                        <div class="alert alert-info text-left d-flex"> <InfoOutlinedIcon /> <span>Enter your information to register an account.</span></div>
                                    </Col> */}
                  <Col
                    xs={12}
                    sm={12}
                    md={{ span: 6, offset: 3 }}
                    className="p-0"
                  >
                    <div className="rcs_account_content_left">
                      <Form
                        onSubmit={submitRegisterWholesaler}
                        className="hl_new_login_form"
                      >
                        <Row>
                          <Col xs={12} sm={6} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                size="lg"
                                type="text"
                                placeholder="First Name"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={6} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                size="lg"
                                type="text"
                                placeholder="Last Name"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                size="lg"
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={6} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Phone</Form.Label>
                              <Form.Control
                                size="lg"
                                type="number"
                                placeholder="Phone"
                                min="0"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                InputProps={{ inputProps: { min: 0 } }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Company</Form.Label>
                              <Form.Control
                                size="lg"
                                type="text"
                                placeholder="Company"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Address Line 1</Form.Label>
                              <Form.Control
                                size="lg"
                                type="text"
                                placeholder="Address Line 1"
                                value={address1}
                                onChange={(e) => setAddress1(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Address Line 2</Form.Label>
                              <Form.Control
                                size="lg"
                                type="text"
                                placeholder="Address Line 2"
                                value={address2}
                                onChange={(e) => setAddress2(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Country</Form.Label>
                              <Form.Control
                                size="lg"
                                type="text"
                                placeholder="Country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>City</Form.Label>
                              <Form.Control
                                size="lg"
                                type="text"
                                placeholder="City"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={6} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Select State/Province</Form.Label>
                              <Form.Control
                                size="lg"
                                type="text"
                                placeholder="Select State/Province"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={6} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Postal Code</Form.Label>
                              <Form.Control
                                size="lg"
                                type="text"
                                placeholder="Postal Code"
                                min="0"
                                value={postalcode}
                                onChange={(e) => setPostalcode(e.target.value)}
                                required
                                InputProps={{ inputProps: { min: 0 } }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Website</Form.Label>
                              <Form.Control
                                size="lg"
                                type="text"
                                placeholder="e.g. https://example.com"
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Type</Form.Label> <br></br>
                              <Form.Select
                                size="lg"
                                width="100%"
                                value={selectedType}
                                onChange={(e) =>
                                  setSelectedType(e.target.value)
                                }
                              >
                                <option value={"DiamondDealer"}>
                                  Diamond Dealer
                                </option>
                                <option value={"Jeweller"}>Jeweller</option>
                                <option value={"Other"}>Other</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>VAT Number</Form.Label>
                              <Form.Control
                                size="lg"
                                type="number"
                                placeholder="VAT Number"
                                min="0"
                                value={vat}
                                onChange={(e) => setVat(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                If "Other", please specify
                              </Form.Label>
                              <Form.Control
                                size="lg"
                                type="text"
                                placeholder='If "Other", please specify'
                                value={other}
                                onChange={(e) => setOther(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="rcs_custom_padding">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                size="lg"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={
                                      <Image
                                        src={WhiteCheck}
                                        className="white_check_img box-shadow"
                                        width="25px"
                                        height="25px"
                                      />
                                    }
                                    checkedIcon={
                                      <Image
                                        src={blackCheck}
                                        className="black_check_img"
                                        width="25px"
                                        height="25px"
                                      />
                                    }
                                  />
                                }
                                label="Sign up to receive our newsletter."
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="rcs_custom_padding m-auto" sm={12}>
                            <button
                              type="submit"
                              className="hl_new_black_btn"
                              style={{ margin: "0 auto", display: "block" }}
                            >
                              Create an account
                            </button>
                            <div className="text-center text-dark m-auto pt-3">
                              <NavLink
                                to="/login"
                                className="text-center text-dark m-auto"
                              >
                                Cancel
                              </NavLink>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal show={loader}>
        <Loader />
      </Modal>
    </>
  );
};

export default WholesalerRegister;
