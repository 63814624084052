import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";


import round from "../../../../Assets/images/hala-london-img/diamond-shap/round-diamond.png";
import princess from "../../../../Assets/images/hala-london-img/diamond-shap/princess-diamond.png";
import cushion from "../../../../Assets/images/hala-london-img/diamond-shap/cushion-diamond.png";
import emerald from "../../../../Assets/images/hala-london-img/diamond-shap/emerald-diamond.png";
import oval from "../../../../Assets/images/hala-london-img/diamond-shap/oval-diamond.png";
import radiant from "../../../../Assets/images/hala-london-img/diamond-shap/radiant-diamond.png";
import asscher from "../../../../Assets/images/hala-london-img/diamond-shap/asscher-diamond.png";
import marquise from "../../../../Assets/images/hala-london-img/diamond-shap/marquise-diamond.png";
import heart from "../../../../Assets/images/hala-london-img/diamond-shap/heart-diamond.png";
import pear from "../../../../Assets/images/hala-london-img/diamond-shap/pear-diamond.png";

import prevImg from "../../../../Assets/images/hala-london-img/prev-img.png";
import nextImg from "../../../../Assets/images/hala-london-img/next-img.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
function ShopDiamondShape() {
  var history = useHistory();

  const shapData = [
    {
      shapImg: round,
      shapName: "Round",
      shapLink: "#",
    },
    {
      shapImg: princess,
      shapName: "Princess",
      shapLink: "#",
    },
    {
      shapImg: cushion,
      shapName: "Cushion",
      shapLink: "#",
    },
    {
      shapImg: emerald,
      shapName: "Emerald",
      shapLink: "#",
    },
    {
      shapImg: oval,
      shapName: "Oval",
      shapLink: "#",
    },
    {
      shapImg: radiant,
      shapName: "Radiant",
      shapLink: "#",
    },
    {
      shapImg: asscher,
      shapName: "Asscher",
      shapLink: "#",
    },
    {
      shapImg: marquise,
      shapName: "Marquise",
      shapLink: "#",
    },
    {
      shapImg: heart,
      shapName: "Heart",
      shapLink: "#",
    },
    {
      shapImg: pear,
      shapName: "Pear",
      shapLink: "#",
    },
  ];

  var settings2 = {
    dots: false,
    infinite: true,
    centerMode: false,
    speed: 500,
    accessibility: true,
    arrows: true,
    prevArrow: <img src={prevImg} alt="prev img" />,
    nextArrow: <img src={nextImg} alt="prev img" />,
    slidesToShow: 10,
    slidesToScroll: 1,
    focusOnSelect: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const shopbyshape = (value) => {
    var data1 = {
      shape: value,
    };
    sessionStorage.setItem("bw_d_filter", JSON.stringify(data1));
    history.push("/diamonds");
    window.scrollTo(0, 0);
  };

  return (
    <>
      <section className="rcs_ring_style_section mt-4">
        <Container className="showcase-container">
          <Row>
            <Col md={12}>
              <div className="hl_diamond_headings heading-wrapper text-center">
                <h2>shop diamonds by shape</h2>
                <p>Discover Your Perfect Fit</p>
              </div>
            </Col>
            <Col sm={12} md={12} lg={12}>
              {isMobile ? (
                <div className="mobile_view_shop_diamond_shap">
                  <Slider
                    {...settings2}
                    className="hl_diamond_shop_by_shap_slider"
                  >
                    {shapData.map((val, index) => (
                      <div className="hl_shap_img_box_outer">
                        <div
                          className="hl_shap_img_box"
                          onClick={() => shopbyshape(val.shapName)}
                        >
                          <Image
                            src={val.shapImg}
                            className="img-fluid"
                            alt={val.shapName}
                          />
                          <p>{val.shapName}</p>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              ) : (
                <div className="hl_diamod_shap">
                  <ul>
                    {shapData.map((val, index) => (
                      <li>
                        <div
                          className="hl_shap_img_box"
                          onClick={() => shopbyshape(val.shapName)}
                        >
                          <Image
                            src={val.shapImg}
                            className="img-fluid"
                            alt={val.shapName}
                          />
                          <p>{val.shapName}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Col>
           
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ShopDiamondShape;
